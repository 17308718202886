<template>
  <div v-if="isThemeLoading">
    <router-view> <router-view name="helper" /></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isThemeLoading: false,
    };
  },
  async created() {
    this.$EventBus.$on("getTheme", () => {
      this.getIconTheme();
    });
    this.getIconTheme();
  },
  methods: {
    padZero(str, len) {
      len = len || 2;
      var zeros = new Array(len).join("0");
      return (zeros + str).slice(-len);
    },
    invertColor(hex, bw) {
      if (!hex) return "#000000";
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    async getIconTheme() {
      await this.$store.dispatch("getTheme");
      const data = this.$store.state.theme.themeLogo;

      let bodyStyles = document.body.style;
      bodyStyles.setProperty("--primary-color", data.result.themePrimaryColor);
      bodyStyles.setProperty(
        "--secondary-color",
        data.result.themeSecondaryColor
      );
      bodyStyles.setProperty(
        "--font-color",
        this.invertColor(data.result.themeSecondaryColor, true)
      );
      bodyStyles.setProperty(
        "--font-primary-color",
        this.invertColor(data.result.themePrimaryColor, true)
      );
      this.isThemeLoading = true;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>

import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
    state: {
        modalAlertLogin : false,
        profileLists : {},
        formList : {},
        formDetail : {},
        campaignDetail : {},
        respUpdateCampaign : {},
        privilegeList : {},
        respCreateForm : {},
        respUpdateForm : {},
        userRegisterForm : {},
        respExport : {},
        fieldtype: [],
    }
})

export const mutations = {
    setModalAlertLogin: async function(state,val){
        state.modalAlertLogin = val;
    },
    setProfileLists: async function(state,val){
        state.profileLists = val;
    },
    setFormList: async function(state,val){
        state.formList = val;
    },
    setFormDetail: async function(state,val){
        state.formDetail = val;
    },
    setCampaignDetail: async function(state,val){
        state.campaignDetail = val;
    },
    setRespUpdateCampaign: async function(state,val){
        state.respUpdateCampaign = val;
    },
    setPrivilegeList: async function(state,val){
        state.privilegeList = val;
    },
    setRespCreateForm: async function(state,val){
        state.respCreateForm = val;
    },
    setRespUpdateForm: async function(state,val){
        state.respUpdateForm = val;
    },
    setUserRegisterForm: async function(state,val){
        state.userRegisterForm = val;
    },
    setRespExport: async function(state,val){
        state.respExport = val;
    },
    setFieldType: async function(state,val){
        state.fieldtype = val;
    },
}

export const actions = {
    getFieldProfiletypes: async ({ commit }) => {
        await axios.get(`${process.env.VUE_APP_API}/Form/fieldprofiletype`)
        .then(async data => {
            commit('setProfileLists',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        });
    },
    getFormList: async ({ commit } , payload) => {
        await axios.post(`${process.env.VUE_APP_API}/Form/getlist`, payload)
        .then(async data => {
          commit('setFormList',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    getFormDetail: async ({ commit } , payload ) => {
        await axios.get(`${process.env.VUE_APP_API}/Form/${payload}` )
        .then(async data => {
            commit('setFormDetail',data.data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    updateCampaign: async ({ commit } , payload ) => {
        await axios.post(`${process.env.VUE_APP_API}/Form/updatereport` , payload )
        .then( async data => {
            commit('setRespUpdateCampaign',data.data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    getPrivilegeList: async ({ commit } , payload ) => {
        await axios.post(`${process.env.VUE_APP_API}/Form/getprivilege/${payload.id}` , payload.filter )
        .then(async data => {
            commit('setPrivilegeList',data.data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    createdForm: async ({ commit } , payload ) => {
        await axios.post(`${process.env.VUE_APP_API}/Form` , payload )
        .then( async data => {
            commit('setRespCreateForm',data.data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    updateForm: async ({ commit } , payload ) => {
        await axios.put(`${process.env.VUE_APP_API}/Form` , payload )
        .then( async data => {
            commit('setRespUpdateForm',data.data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    getUserRegisterForm: async({ commit } , payload) => {
        await axios.post(`${process.env.VUE_APP_API}/Form/getcampaignuser/${payload.id}` , payload.filter)
        .then(async data => {
            commit('setUserRegisterForm',data.data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    exportCampaignForm: async({ commit } , payload ) => {
        await axios.get(`${process.env.VUE_APP_API}/Form/exportcampaignuser/${payload}`, {
            responseType: 'blob'
        })
        .then((data) => {
            commit('setRespExport',data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    getCampaignUserDetail: async ({ commit } , payload ) => {
        await axios.get(`${process.env.VUE_APP_API}/Form/getcampaignuserdetail/${payload.formId}/${payload.id}` )
        .then(async data => {
            commit('setCampaignDetail',data.data);
        },(error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        })
    },
    getFieldTypes: async ({ commit }) => {
        await axios.get(`${process.env.VUE_APP_API}/Form/fieldtype`)
        .then(async data => {
            commit('setFieldType',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        });
    },
}

export default {
    state,
    actions,
    mutations
}
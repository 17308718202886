import Vue from "vue";
import Vuex from "vuex";
import moduleConnectAPI from "./connectAPI/index";
import coupon from "./modules/coupon";
import moduleSalesChannel from "./connectAPISalesChannel/index";
import stampcard from "./modules/stampcard";
import modulePrivilegeCode from "./connectAPIPrivilegeCode/index";
import form from "./modules/form";
import extraPoint from "./modules/extraPoint";
import allRightRequest from "./modules/allrightrequest";
import permission from "./modules/permission";
import moduleVideo from "./connectAPIVideo/index";
import automation from "./modules/automation";
import importFile from "./modules/importFile";
import memberTier from "./modules/memberTier";
import theme from "./modules/theme";
import redeem from "./modules/redeem";
import report from "./modules/report";
import product from "./modules/product";
import setting from "./modules/setting";
import activitylog from "./modules/activitylog";

Vue.use(Vuex);
import axios from "axios";
import VueCookies from "vue-cookies";
import swal2 from "sweetalert2";
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = Vue.$cookies.isKey("back_office_admin_token")
    ? config.url.includes("lazada")
      ? ""
      : `Bearer ${Vue.$cookies.get("back_office_admin_token")}`
    : "";
  config.headers.common["API-KEY"] = config.url.includes("lazada")
    ? ""
    : process.env.VUE_APP_API_KEY;
  return config;
});

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.data.message == "Permission denied") {
    window.location.href = "/403";
  }
  if (error.response.status == 401) {
    swal2
      .fire("No access to information", "Please try again", "error")
      .then(() => {
        let cookiesDomain = window.location.hostname;
        VueCookies.remove("back_office_admin_token", null);
        VueCookies.remove("permission_menu_admin", null);
        VueCookies.remove("back_office_admin_permission", null);
        VueCookies.remove("back_office_admin_user_guid", null);
        VueCookies.remove("back_office_admin_email", null);
        VueCookies.remove("back_office_admin_userName", null);
        window.location.href = "/";
      });
  }
  if (error.response.status == 500) {
    swal2
      .fire("Internal Server Error.", "Please try again", "error")
      .then(() => {
        let cookiesDomain = window.location.hostname;
        VueCookies.remove("back_office_admin_token", null);
        VueCookies.remove("permission_menu_admin", null);
        VueCookies.remove("back_office_admin_permission", null);
        VueCookies.remove("back_office_admin_user_guid", null);
        VueCookies.remove("back_office_admin_email", null);
        VueCookies.remove("back_office_admin_userName", null);
        window.location.href = "/";
      });
  }
  return error;
});
Vue.prototype.$Axios = axios;
export const store = new Vuex.Store({
  namespaced: true,
  state: {
    formatDateNew: "DD/MM/YYYY",
    formatDate: "DD MMM YYYY",
    formatDateTime: "DD MMM YYYY HH:mm",
    formatTime: "HH:mm",
    screenSize: {
      width: 0,
      height: 0,
    },
  },
  mutations: {
    setScreenSize(state, screenSize) {
      state.screenSize.width = screenSize.width;
      state.screenSize.height = screenSize.height;
    },
  },
  actions: {},
  modules: {
    moduleConnectAPI,
    coupon,
    moduleSalesChannel,
    stampcard,
    modulePrivilegeCode,
    form,
    extraPoint,
    allRightRequest,
    moduleVideo,
    automation,
    importFile,
    memberTier,
    theme,
    redeem,
    report,
    product,
    setting,
    activitylog,
    permission,
  },
});
